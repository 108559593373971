import * as React from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import cn from 'classnames';
import PrevIcon from './icons/CarouselPrev';
import NextIcon from './icons/CarouselNext';

type Props = Settings & {
  readonly children: React.ReactNode;
  readonly arrowStyles?: string;
};

type PropsArrow = {
  readonly type: 'prev' | 'next';
  readonly onClick?: () => void;
  readonly styles?: string;
};

const CarouselArrow = ({ type, onClick, styles }: PropsArrow) => {
  const Icon = type === 'prev' ? PrevIcon : NextIcon;

  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(
        `${
          styles || 'top-1/2'
        } w-9 h-9 absolute  transform -translate-y-1/2 rounded-full bg-white bg-opacity-60 z-10 transition-colors duration-300 ease-in-out hover:bg-opacity-90 lg:hidden`,
        type === 'prev' ? 'left-6' : 'right-6',
      )}
    >
      <Icon size={36} />
    </button>
  );
};

export const Carousel = ({ children, arrowStyles, ...restSettings }: Props) => (
  <Slider
    prevArrow={<CarouselArrow type="prev" styles={arrowStyles} />}
    nextArrow={<CarouselArrow type="next" styles={arrowStyles} />}
    {...restSettings}
  >
    {children}
  </Slider>
);
