import * as React from 'react';
import { graphql } from 'gatsby';
import { FaqPageQuery } from 'generated/graphql';
import { MainLayout } from 'components/MainLayout';
import { ContentTitle } from 'components/ContentTitle';
import { YMapWithDeliveryZone } from 'components/YMapWithDeliveryZone';
import { Footer } from 'components/Footer';
import { InstagramReviews } from 'components/InstagramReviews';

type Props = {
  readonly data: FaqPageQuery;
};

const blockCn = 'mb-8 lg:mb-6';

const titleCn = 'text-xl font-bold mb-3';

const FaqPage = ({ data }: Props) => (
  <MainLayout>
    <div className="container">
      <div className="pb-10 lg:pb-7 px-2">
        <ContentTitle title="FAQ" backButton />
        <div className={blockCn}>
          <h3 className={titleCn}>1. Куда доставляете?</h3>
          <YMapWithDeliveryZone data={data} />
        </div>
        {data.faqItems.map((b, i) => (
          <div key={b.id} className={blockCn}>
            <h3 className={titleCn}>
              {i + 2}. {b.question}
            </h3>
            <div>{b.answer}</div>
          </div>
        ))}
      </div>
    </div>
    <InstagramReviews />
    <Footer />
  </MainLayout>
);

export const query = graphql`
  query FaqPageQuery {
    faqItems(section: "faqpage") {
      id
      answer
      question
    }
    ...YMapWithDeliveryZone_data
  }
`;

export default FaqPage;
