import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import cn from 'classnames';
import { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { InstagramReviewsQuery } from 'generated/graphql';
import * as s from './InstagramReviews.module.css';
import { Carousel } from './Carousel';

const settings: Settings = {
  slidesToShow: 6,
  responsive: [
    {
      breakpoint: 1380,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1040,
      settings: {
        slidesToShow: 1,
        variableWidth: true,
      },
    },
  ],
};

export const InstagramReviews = () => {
  const queryData = useStaticQuery<InstagramReviewsQuery>(
    graphql`
      query InstagramReviewsQuery {
        instagramReviews {
          id
          username
          url
          imageUrl(size: 195)
        }
      }
    `,
  );

  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className="container mb-12 overflow-hidden lg:mb-7">
      <div className="text-2xl font-bold mb-3 lg:text-xl mx-2">О нас в Instagram</div>
      <div
        className="h-0.5 mb-5 lg:h-px"
        style={{ background: 'linear-gradient(to right, #e1004a, #c000ac 27%, #ffb400 69%, #ff5400)' }}
      />
      <div className={s.sliderWrapper}>
        {mounted ? (
          <Carousel {...settings} className={cn('clients-slider', s.slider)}>
            {queryData.instagramReviews.map((r) => (
              <a key={r.id} href={r.url} target="_blank" rel="noopener noreferrer" className={s.item}>
                <img src={r.imageUrl} alt={r.username} className={s.itemImage} />
                <span className={s.itemAuthor}>{r.username}</span>
              </a>
            ))}
          </Carousel>
        ) : (
          <div className={s.slider} />
        )}
      </div>
    </div>
  );
};
